import React from "react";
import { useDropzone } from "react-dropzone";
import { Text, useToast, useColorMode } from "@chakra-ui/react";

import "./upload.scss";
import upload from "../../../../assets/upload.png";
import { checkColorMode } from "../../../../global/changeColor";
const UploadComponent = (props) => {
  //
  const toast = useToast();
  const { colorMode } = useColorMode();
  //
  const { setFieldValue, currntFiles, fromMedical } = props;
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/png, image/jpeg,image/jpg, application/pdf",
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (fromMedical && acceptedFiles[0].size > 10 * 1024 * 1024) {
        return toast({
          title: "The file size must be less than  or equal 200mb",
          status: "error",
          duration: 7000,
          isClosable: true,
          position: "top-right",
        });
      }
      setFieldValue("files", [...acceptedFiles]);
      currntFiles(acceptedFiles);
      if (rejectedFiles.length) {
        const erroes = rejectedFiles[0].errors;
        Object.entries(erroes).map(([key, value]) => {
          return toast({
            title: value.message,
            status: "error",
            duration: 7000,
            isClosable: true,
            position: "top-right",
          });
        });
      }
    },
  });
  return (
    <>
      <div {...getRootProps({ className: "dropzone add-wrap" })}>
        <div className="w-100 d-flex justify-content-center">
          <img src={upload} alt="upload" />
        </div>
        <div className="text-center mt-2">
          <input {...getInputProps()} />

          <Text className="fw-bold" color="gray.400">
            {" "}
            Drop your files here
          </Text>
          <Text color="gray.400">(JPG, PNG, JPEG, PDF)</Text>
          <Text color={checkColorMode(colorMode)} className="fw-bold mt-4">
            Click to upload
          </Text>
        </div>
      </div>
    </>
  );
};

export default UploadComponent;
